import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ISale, IUser } from '../interfaces/all.interface';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeaderboardService {
  private http = inject(HttpClient);

  private leaderboard_url = environment.leaderboard_url;
  constructor() {}

  getSales() {
    return this.http.get<ISale[]>(`${this.leaderboard_url}/sales`);
  }
  getUser() {
    return this.http.get<IUser[]>(`${this.leaderboard_url}/user`);
  }
  getIcon(fileName: string) {
    return this.http
      .get(`${this.leaderboard_url}/media/icons/${fileName}`, {
        responseType: 'blob',
      })
      .pipe(switchMap((file) => readFileAsDataURL(file)));
  }
}
function readFileAsDataURL(file: Blob): Observable<string> {
  return new Observable<string>((observer) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => observer.next(reader.result as string);
    reader.onerror = (error) => observer.error(error);

    return () => {
      reader.abort();
    };
  });
}
